import axios from "axios";
import { getCookie, setCookie, deleteCookie } from "cookies-next";
import { v4 as uuidv4 } from "uuid";
import { getConfigs, getUrlParams } from "../utils/common";
import { actionRefreshToken } from "./common";

export const API_URL =
  process.env.REACT_APP_API_SERVICE_URL || "https://api.classforme.edu.vn";

let isRefreshing = false;
let subscribers = [];

function addSubscriber(callback) {
  subscribers.push(callback);
}

function onRrefreshed(new_token) {
  subscribers = subscribers.map((callback) => callback(new_token));
  subscribers = [];
}
const request = axios.create({
  baseURL: API_URL,
  timeout: 40000,
});

const logout = () => {
  subscribers = [];
  const paramsSearch = getUrlParams(window.location.search);
  const cfg = getConfigs(paramsSearch?.s);

  deleteCookie(
    cfg?.tokenKey,
    cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
  );
  deleteCookie(
    cfg?.refreshTokenKey,
    cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
  );
  deleteCookie(cfg?.tokenKey);
  deleteCookie(cfg?.refreshTokenKey);
  // if (typeof window !== "undefined") window.location.href = "/";
};

request.interceptors.request.use(
  (config) => {
    if (
      config.url.indexOf("/login") !== -1 ||
      config.url.indexOf("/refresh-token") !== -1
    ) {
      delete config.headers.Authorization;
    }
    return { ...config, headers: { ...config?.headers } };
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const paramsSearch = getUrlParams(window.location.search);
    const cfg = getConfigs(paramsSearch?.s);
    if (error?.response && error?.response?.status === 401) {
      const refreshToken = getCookie(
        cfg?.refreshTokenKey,
        cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
      );
      if (error.config.url.indexOf("refresh-token") !== -1) {
        logout();
      }
      if (refreshToken && !originalRequest._retry) {
        originalRequest._retry = true;
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const res = await actionRefreshToken({
              clientId: cfg?.clientId,
              userType: cfg?.userType,
              grantType: "refresh_token",
              token: refreshToken,
              // clientId: CLIENT_ID,
              // userType: "STUDENT",
            });
            const { data } = res.data;
            isRefreshing = false;
            setCookie(
              cfg?.tokenKey,
              data?.accessToken,
              cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
            );
            setCookie(
              cfg?.refreshTokenKey,
              data?.refreshToken,
              cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
            );
            onRrefreshed(data.accessToken);
            return new Promise((resolve) => {
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.accessToken;
              originalRequest.headers["Authorization"] =
                "Bearer " + data.accessToken;
              resolve(axios(originalRequest));
            });
          } catch (error) {
            logout();
          }
        }
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((new_token) => {
            originalRequest.headers.Authorization = `Bearer ${new_token}`;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      } else {
        logout();
      }
    }
    return Promise.reject(error.response);
  }
);

const api = (options) => {
  const paramsSearch = getUrlParams(window.location.search);
  const cfg = getConfigs(paramsSearch?.s);
  const token = getCookie(
    cfg?.tokenKey,
    cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
  );
  const uuid = getCookie(
    "uuid",
    cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
  );

  let headers = {
    "Accept-Language": "vi",
    "m-platform": "WEB",
    tcode: "cfm",
    ...options.headers,
  };
  if (token) headers = { Authorization: `Bearer ${token}`, ...headers };
  if (uuid) {
    headers = { ...headers, "m-device-id": uuid };
  } else {
    const newUuid = uuidv4();
    setCookie(
      "uuid",
      newUuid,
      cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
    );
    headers = { ...headers, "m-device-id": newUuid };
  }

  return request({
    baseURL: API_URL,
    responseType: "json",
    ...options,
    headers,
  });
};

export default api;
