const has = Object.prototype.hasOwnProperty;
export const isEmpty = (prop) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, "length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  );
};

export const getConfigs = (source) => {
  if (source === "teacher") {
    return {
      clientId: "c9a21722-4580-4ff8-ba94-e62f1343193c",
      userType: "TEACHER",
      tokenKey: "teacher-token",
      refreshTokenKey: "teacher-refresh-token",
      domainUrl:
        process.env.REACT_APP_PUBLIC_TEACHER_DOMAIN_URL ||
        "https://teacher.classforme.edu.vn",
      domainCookieURL: process.env.REACT_APP_DOMAIN_URL || "classforme.edu.vn",
    };
  }
  if (source === "teacher_cn") {
    return {
      clientId: "c9a21722-4580-4ff8-ba94-e62f1343193c",
      userType: "TEACHER",
      tokenKey: "teacher-cn-token",
      refreshTokenKey: "teacher-cn-refresh-token",
      domainUrl: "https://chongfengclass.cn/",
      domainCookieURL: null,
    };
  }
  if (source === "teacher_ko") {
    return {
      clientId: "c9a21722-4580-4ff8-ba94-e62f1343193c",
      userType: "TEACHER",
      tokenKey: "teacher-ko-token",
      refreshTokenKey: "teacher-ko-refresh-token",
      domainUrl: "https://koreanaireclass.kr",
      domainCookieURL: null,
    };
  }
  // student
  return {
    clientId: "ecb8bbf1-a250-4db6-888d-782bf3c2e6ff",
    userType: "STUDENT",
    tokenKey: "student-token",
    refreshTokenKey: "student-refresh-token",
    domainUrl:
      process.env.REACT_APP_PUBLIC_STUDENT_DOMAIN_URL ||
      "https://classforme.edu.vn",
    domainCookieURL: process.env.REACT_APP_DOMAIN_URL || "classforme.edu.vn",
  };
};
export const getUrlParams = (search) => {
  let result = {};
  let params = (search.split("?")[1] || "").split("&");
  for (var param in params) {
    if (params.hasOwnProperty(param)) {
      let paramParts = params[param].split("=");
      result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
    }
  }
  return result;
};
