import api from "./api";

const headers = {
  "x-msg-app": "CFM",
};

export const actionRefreshToken = async (data) => {
  return await api({
    url: `/mid/v1/auth/refresh-token`,
    method: "post",
    data,
  });
};

export const getClassInfo = (data) => {
  return api({
    url: `/clms/classes/lessons/get-live-link`,
    method: "post",
    data,
  });
};
export const logJoinMeeting = (data) => {
  return api({
    url: `/clms/classes/lessons/logs`,
    method: "post",
    data,
  });
};
export const sendGift = async (data) => {
  return await api({
    url: `/promotions/v1/gift/send-gift`,
    method: "post",
    data,
  });
};
export const getGiftInfo = async (skus) => {
  return await api({
    url: `/css/v1/course-offering/get-by-skus`,
    method: "get",
    params: { skus },
  });
};
export const registerNotification = (firebaseToken) => {
  return api({
    headers,
    url: "/messaging/notifications/register",
    method: "post",
    data: { token: firebaseToken },
  });
};
export const unregisterNotification = (firebaseToken) => {
  return api({
    headers,
    url: "/messaging/notifications/unregister",
    method: "post",
    data: { token: firebaseToken },
  });
};
