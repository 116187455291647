import React from "react";
import clsx from "clsx";

const BasicDialog = (props) => {
  const { title, subTitle, open, handleClose, children, titleClass } = props;
  if (!open) return <></>;
  return (
    <div
      className={clsx(
        "fixed w-full h-full bg-[rgba(0,0,0,0.6)] z-[1000] flex items-center justify-center",
        "p-4"
      )}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='p-6 bg-white max-w-[640px] rounded-[16px] md:rounded-[12px]'>
        <div
          className='w-full flex justify-end cursor-pointer'
          onClick={handleClose}>
          <CloseIcon />
        </div>
        {title && (
          <div className='w-full flex flex-col items-center justify-between px-0'>
            <p
              className={clsx(
                "w-full text-primary text-16-24 lg:text-24-32 text-center font-inter-700",
                subTitle ? "lg:mb-[24px] mb-2" : "lg:mb-[16px] mb-2",
                titleClass || ""
              )}>
              {title}
            </p>
            {subTitle && (
              <p className='text-black font-inter-400 md:text-16-24 text-14-20 lg:mb-6 mb-2 text-center'>
                {subTitle}
              </p>
            )}
          </div>
        )}
        <div className='px-0'>{children}</div>
      </div>
    </div>
  );
};

export default BasicDialog;

const CloseIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.5'>
        <path
          d='M18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5Z'
          stroke='#888888'
          stroke-width='1.5'
          stroke-miterlimit='10'
        />
        <path
          d='M22.5 13.5L13.5 22.5'
          stroke='#888888'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M22.5 22.5L13.5 13.5'
          stroke='#888888'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  );
};
