import React, { useEffect, useState } from "react";
import { onMessage, getMessaging, getToken } from 'firebase/messaging'
import firebaseApp from '../utils/firebase.config'
import { getCookie, setCookie } from 'cookies-next'
import { registerNotification } from "../api/common";
import ReceivePromotionNotificationDialog from "./ReceivePromotionNotificationDialog";
import { getConfigs, getUrlParams } from "../utils/common";

const FCM_TOKEN = 'fcm_token'
const Notification = () => {
  const [openPromotionNotificationDialog, setOpenPromotionNotificationDialog] =
    useState(null);

  const handleRealTimeMessage = async () => {
    const messaging = getMessaging(firebaseApp)
    const fcmToken = getCookie(FCM_TOKEN)
    if (fcmToken) {
      onMessage(messaging, (payload) => {
        console.log('payload', payload)
        if ((payload as any)?.data.TYPE === "cfm.notification.gift") setOpenPromotionNotificationDialog((payload as any)?.data.PROMOTION)
      })
    }
  }
  const initRegisterNotification = async () => {
    if (typeof navigator !== undefined && window !== undefined) {
      const messaging = getMessaging(firebaseApp)
      try {
        const fcmToken = await getToken(messaging)
        if (fcmToken) {
          const paramsSearch: any = getUrlParams(window.location.search);
          const cfg = getConfigs(paramsSearch?.s);
          setCookie(FCM_TOKEN, fcmToken, cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {});
          registerNotification(fcmToken)
          handleRealTimeMessage()
        }
      } catch (error: any) {
        console.error(error?.data?.message)
      }
    }
  }
  useEffect(() => {
    initRegisterNotification()
    handleRealTimeMessage()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ReceivePromotionNotificationDialog
        openPromotionNotificationDialog={openPromotionNotificationDialog}
        setOpenPromotionNotificationDialog={setOpenPromotionNotificationDialog}
      />
    </>
  );
};

export default Notification;
