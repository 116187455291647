import React, { useState } from "react";
import { sendGift } from "../api/common";
import { isEmpty } from "../utils/common";
import BasicDialog from "./BasicDialog";
import CustomRadio from "./CustomRadio";
import GiftImage from "../assest/gift.svg";
import GiftSuccessImage from "../assest/gift_success.svg";
import clsx from "clsx";

const RECEIVER_TYPE = {
  PARENT: "PARENT",
  STUDENT: "STUDENT",
};
const ReceivePromotionNotificationDialog = ({
  openPromotionNotificationDialog,
  setOpenPromotionNotificationDialog,
}) => {
  const [type, setType] = useState(RECEIVER_TYPE.PARENT);
  const [receiver, setReceiver] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });

  const handleClose = () => {
    setOpenPromotionNotificationDialog(null);
    setSuccess(false);
    setError({ show: false, message: "" });
    setReceiver("");
    setType(RECEIVER_TYPE.PARENT);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let dataDTO = {
        receiverType: type,
        promotionId: openPromotionNotificationDialog,
      };
      if (isStudent) dataDTO = { ...dataDTO, receiver };
      const res = await sendGift(dataDTO);
      if (res.data) setSuccess(true);
    } catch (err) {
      setError({
        show: true,
        message:
          err?.data?.message ||
          "Số điện thoại đã nhập chưa đăng ký tài khoản tại Class for Me",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderSuccessContent = () => {
    return (
      <div className='flex flex-col gap-2 md:gap-4'>
        <div className='flex items-center justify-center'>
          <img
            src={GiftSuccessImage}
            width={210}
            height={130}
            alt=''
            className='w-[194px] md:w-[210px] h-[120px] md:h-[130px]'
          />
        </div>
        <div className='flex flex-col gap-2'>
          <p className='text-16-24 lg:text-20-28 font-inter-700 text-[#252EAE] text-center'>
            {isStudent
              ? "Gửi quà tặng thành công!"
              : "Nhận mã quà tặng thành công!"}
          </p>
          <p className='text-14-20 font-inter-400 text-black text-center'>
            {`Xin chúc mừng! Mã quà tặng tham gia khóa học miễn phí đã được gửi. Vui lòng kiểm tra thông tin về quà tặng tại mục thông báo trên tài khoản Class for Me của ${
              isStudent ? "người nhận" : "bạn"
            }.`}
          </p>
        </div>

        <div className='flex items-center gap-2 sm:gap-4 justify-center pt-3 md:pt-0'>
          <button
            onClick={handleClose}
            className={`px-2 lg:px-4 !bg-[#FD7D9E]`}>
            <span className='whitespace-nowrap text-14-20 lg:text-16-24 font-inter-500'>
              Đồng ý
            </span>
          </button>
        </div>
      </div>
    );
  };

  const isStudent = type === RECEIVER_TYPE.STUDENT;
  const isDisabled =
    loading ||
    (isStudent && isEmpty(receiver)) ||
    isEmpty(openPromotionNotificationDialog);
  return (
    <>
      <BasicDialog
        open={!isEmpty(openPromotionNotificationDialog)}
        handleClose={handleClose}>
        {isSuccess ? (
          renderSuccessContent()
        ) : (
          <div className='flex flex-col gap-2 md:gap-3'>
            <div className='flex items-center justify-center'>
              <img
                src={GiftImage}
                width={160}
                height={160}
                alt=''
                className='w-[120px] md:w-[160px] h-[120px] md:h-[160px]'
              />
            </div>
            <div>
              <p className='text-16-24 lg:text-20-28 font-inter-700 text-[#252EAE] text-center'>
                Xin chúc mừng!
              </p>
              <p className='text-16-24 lg:text-20-28 font-inter-700 text-[#252EAE] text-center'>
                Bạn đã nhận được một khóa học miễn phí từ Class for Me.
              </p>
            </div>
            <div>
              <p className='text-14-20 font-inter-400 text-black mb-2'>
                Bạn có thể gửi tặng khóa học này cho con của mình trong trường
                hợp con của bạn sử dụng một tài khoản Class for Me khác.
              </p>
              <div className='flex flex-col gap-[4px] text-14-20 font-inter-400 text-black'>
                <CustomRadio
                  label='Nhận quà cho tài khoản này'
                  checked={type === RECEIVER_TYPE.PARENT}
                  handleClick={() => {
                    setType(RECEIVER_TYPE.PARENT);
                    if (receiver) setReceiver("");
                  }}
                />
                <CustomRadio
                  label='Gửi quà tặng cho con'
                  checked={type === RECEIVER_TYPE.STUDENT}
                  handleClick={() => setType(RECEIVER_TYPE.STUDENT)}
                />
              </div>
            </div>
            {isStudent && (
              <div className='w-full flex flex-col gap-1 md:gap-2'>
                <p className='text-14-20 font-inter-700 text-black'>
                  Số điện thoại người nhận (số điện thoại đã đăng ký tài khoản
                  Class for Me)
                </p>
                <input
                  placeholder={"Nhập số điện thoại người nhận"}
                  value={receiver}
                  onChange={(e) => {
                    let phoneValue = e.target.value.trim();
                    phoneValue = phoneValue.replace(/\D/g, ""); // Loại bỏ tất cả các ký tự không phải số
                    setReceiver(phoneValue);
                    setError({ show: false, message: "" });
                  }}
                  // error={{ show: true, message: "Số điện thoại đã nhập chưa đăng ký tài khoản tại Class for Me" }}
                  className={clsx(
                    "h-[40px] rounded-[4px] px-3 md:px-4 py-2 border border-[#C2D7FF] focus:outline-none bg-[#ECF3FF] font-inter-400 text-black",
                    error?.show &&
                      "border border-[#EC3A39] hover:border-[#EC3A39] focus:border-[#EC3A39] bg-[#FEF1F1]"
                  )}
                />
                {error.show && (
                  <p className='font-inter-400 text-[#EC3A39] text-14-20'>
                    {error.message}
                  </p>
                )}
              </div>
            )}
            <div className='flex items-center gap-2 sm:gap-4 justify-center'>
              <button
                onClick={handleClose}
                className='px-2 lg:px-4 py-2 bg-[#059341] cursor-pointer rounded-[4px]'>
                <span className='whitespace-nowrap text-14-20 lg:text-16-24 font-inter-500 text-white'>
                  Hủy
                </span>
              </button>
              <button
                onClick={handleSubmit}
                disabled={isDisabled}
                className={`px-2 lg:px-4 py-2 rounded-[4px] ${
                  isDisabled
                    ? "bg-[#B0B0B0] cursor-not-allowed"
                    : "bg-[#FD7D9E] cursor-pointer"
                }`}>
                <span className='whitespace-nowrap text-14-20 lg:text-16-24 font-inter-500 text-white'>
                  Xác nhận
                </span>
              </button>
            </div>
          </div>
        )}
      </BasicDialog>
    </>
  );
};

export default ReceivePromotionNotificationDialog;
