import clsx from "clsx";

import React from "react";

const CustomRadio = (props) => {
  const { label, checked, handleClick, disabled } = props;
  return (
    <div
      className={clsx(
        "flex items-center gap-2",
        disabled ? "cursor-not-allowed opacity-80" : "cursor-pointer"
      )}
      onClick={() => {
        if (disabled) return;
        handleClick();
      }}>
      <div className='w-6 h-6 min-w-[24px] flex items-center justify-center'>
        <div className='w-5 h-5 rounded-[50%] border border-[#252EAE] flex items-center justify-center'>
          {checked && (
            <div
              className={clsx("w-2 h-2 min-w-[8px] rounded-[50%] bg-[#252EAE]")}
            />
          )}
        </div>
      </div>
      <p className='text-14-20 font-inter-400 text-black'>{label}</p>
    </div>
  );
};

export default CustomRadio;
