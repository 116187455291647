import React, { useEffect, useState } from "react";
// import { getCookie } from "cookies-next";
import { ZoomMtg } from "@zoom/meetingsdk";
import { getClassInfo, logJoinMeeting } from "./api/common";
import Notification from "./components/Notification";
import { getConfigs, getUrlParams } from "./utils/common";
import { setCookie } from "cookies-next";
import queryString from "query-string";

// https://github.com/zoom/meetingsdk-react-sample/blob/master/src/App.js

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// ZoomMtg.i18n.load("en-US");
// ZoomMtg.i18n.reload("en-US");
let lessonId = null;
const App = () => {
  const paramsSearch = getUrlParams(window.location.search);
  const [timer, setTimer] = useState(0);
  const [startCount, setStartCount] = useState(false);

  const handleLogPingJoinMeeting = async () => {
    if (lessonId) {
      try {
        await logJoinMeeting({
          lessonId,
          type: "PING",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (paramsSearch) {
      console.log("paramsSearch", paramsSearch);
      if (paramsSearch?.tk || paramsSearch?.rf_tk) {
        const cfg = getConfigs(paramsSearch?.s);
        if (paramsSearch?.tk) {
          setCookie(
            cfg?.tokenKey,
            paramsSearch?.tk,
            cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
          );
        }
        if (paramsSearch?.rf_tk) {
          setCookie(
            cfg?.refreshTokenKey,
            paramsSearch?.rf_tk,
            cfg?.domainCookieURL ? { domain: cfg?.domainCookieURL } : {}
          );
        }
        const newParams = { ...paramsSearch, tk: undefined, rf_tk: undefined };
        window.location.replace(
          `/?${queryString.stringify(newParams, {
            skipNull: true,
            skipEmptyString: true,
          })}`
        );
      }
    }
  }, [paramsSearch]);

  useEffect(() => {
    if (startCount) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 60);
        handleLogPingJoinMeeting();
      }, 60 * 1000); // 60 seconds
      return () => {
        setStartCount(false);
        clearInterval(intervalId); // Clear the interval on component unmount
      };
    } // eslint-disable-next-line
  }, [startCount]);
  const handleLogJoinMeeting = async () => {
    try {
      await logJoinMeeting({
        lessonId,
        type: "JOIN",
        context: { client: "EMBEDDED" },
      });
      setStartCount(true);
    } catch (error) {
      console.log(error);
    }
  };

  const startMeeting = async (data) => {
    const { clientId, id, email, name, pwd, signature } = data?.zoomMeetingInfo;
    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl: getConfigs(paramsSearch?.s).domainUrl,
      defaultView: "gallery",
      disableInvite: true,
      meetingInfo: [],
      // meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          sdkKey: clientId,
          meetingNumber: id,
          passWord: pwd,
          userName: name,
          userEmail: email,
          tk: "",
          zak: "",
          success: (success) => {
            handleLogJoinMeeting();
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };
  const fetchData = async () => {
    try {
      const res = await getClassInfo({ signature: paramsSearch?.signature });
      if (res?.data?.data) {
        startMeeting(res?.data?.data);
        lessonId = res?.data?.data?.lessonId;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Notification />
    </>
  );
};

export default App;
