import { initializeApp, getApps } from "firebase/app";

const firebaseConfigDev = {
  apiKey: "AIzaSyDtryoWK08aXYwbKBepmN8ckasclQtZMaU",
  authDomain: "dev-classforme-app.firebaseapp.com",
  projectId: "dev-classforme-app",
  storageBucket: "dev-classforme-app.appspot.com",
  messagingSenderId: "581962811242",
  appId: "1:581962811242:web:3dae50df6ef7ae3bdb8713",
  measurementId: "G-QF21JD1Q29",
};
const firebaseConfigProduction = {
  apiKey: "AIzaSyBDEgq3ZHLVYxzXBui61v5Ai3CFK8YWUdQ",
  authDomain: "classforme-app-23q4.firebaseapp.com",
  projectId: "classforme-app-23q4",
  storageBucket: "classforme-app-23q4.appspot.com",
  messagingSenderId: "980602630054",
  appId: "1:980602630054:web:f1a8cd011a804efa9b2967",
  measurementId: "G-BY1DWN1K2T",
};
const firebaseConfig = firebaseConfigDev;
// Initialize Firebase
let firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export default firebaseApp;
